<script lang="ts" setup>
import { ref, computed, watch } from "vue";

import { mdiAccountGroup } from "@mdi/js";
import { getUserGroupName } from "@/services/api/iam.api";

const props = defineProps({
  groupId: {
    type: String,
    default: undefined,
  },
  addModus: {
    type: Boolean,
    default: false,
  },
});

const breadCrumbName = ref(undefined);

watch(
  () => props.groupId,
  () => {
    getBreadCrumbName();
  },
  {
    immediate: true,
  }
);

const breadcrumbs = computed(() => {
  let list = [{ default: "IAM-UserGroupsOverview" }];
  if (props.addModus) {
    list.push({
      text: "toevoegen",
      disabled: true,
    });
  } else {
    list.push({
      text: breadCrumbName.value,
      to: { name: "IAM-UserGroupDetails", params: { groupId: props.groupId } },
    });
  }
  return list;
});

async function getBreadCrumbName() {
  console.log("UserGroup getBreadCrumbName");
  if (!props.addModus) {
    const response = await getUserGroupName(props.groupId);
    breadCrumbName.value = response.data;
  }
}

function updateBreadCrumbName() {
  console.log("update breadCrumbName group");
  getBreadCrumbName();
}
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <inn-breadcrumbs :items="breadcrumbs"></inn-breadcrumbs>
    <v-container fluid>
      <header class="app-welcome-message">
        <h2>
          <v-icon>{{ mdiAccountGroup }}</v-icon> Groep
        </h2>
      </header>
      <v-row>
        <v-col cols="2" class="sidebar">
          <v-list nav>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-UserGroupDetails' }" :disabled="addModus">
                <v-list-item-title>Details</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-UserGroupUsers' }" :disabled="addModus">
                <v-list-item-title>Gebruikers</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-divider></v-divider>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-UserGroupResources' }" :disabled="addModus">
                <v-list-item-title>Resources</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-UserGroupProjects' }" :disabled="addModus">
                <v-list-item-title>Projecten</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'IAM-UserGroupRoles' }" :disabled="addModus">
                <v-list-item-title>Rollen</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col>
          <router-view @update-user-group="updateBreadCrumbName"></router-view>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
